import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { HiCheckCircle } from "react-icons/hi"

const ContentContainer = styled.div`
  margin: 5%;
`
const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
`
const IconBox = styled.div`
  display: flex;
  justify-content: center;
  svg {
    height: 100px;
    width: 100px;
  }
`
const MessageBox = styled.div`
  width: 50%;
  height: 50%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  @media (max-width: 1023px) {
    width: 80%;
  }

  h1 {
    font-size: 2.4em;
  }
  h2 {
    font-size: 1.4em;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }
  p {
    margin: 0;
    text-align: center;
  }
`
const HomeBtn = styled.button`
  display: inline-block;
  padding: 0.35em;
  width: 250px;
  border: 2px solid var(--cool-black);
  margin: 20px 0.3em 0.3em 0;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: var(--cool-black);
  font-size: 18px;
  text-decoration: uppercase;
  background-color: #00000000;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    background-color: var(--warm-orange);
    border: 2px solid var(--warm-orange);
    color: var(--white);
    cursor: pointer;
  }
`

export default function SuccessMessage() {
  return (
    <ContentContainer>
      <IconBox>
        <HiCheckCircle />
      </IconBox>
      <MessageContainer>
        <MessageBox>
          <h1>Thank You</h1>
          <h2>for filling out your information!</h2>
          <p>
            We have recieved your message and would like to thank you for
            writing to us. If your enquiry is urgent, please use the telephone
            number listed at the bottom of the page to talk to one of our staff
            members.
          </p>
          <p>Otherwise, we will reply to your message as soon as possible.</p>
          <p>Thanks,</p>
          <p>The Ripplz Team</p>
          <Link to="/">
            <HomeBtn>Go back home</HomeBtn>
          </Link>
        </MessageBox>
      </MessageContainer>
    </ContentContainer>
  )
}
