import React from "react"
import Layout from "../components/HeaderFooter/Layout"
import ContentHeader from "../components/Contact/Success/ContentHeader"
import SuccessMessage from "../components/Contact/Success/SuccessMessage"
import { Helmet } from "react-helmet"

export default function submissionSuccess() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Submission Successful</title>
        <meta
          name="description"
          content="We would like to thank you for writing to us, but if your enquiry is urgent then please use our telephone number listed at the bottom of the web page."
        />
      </Helmet>
      <ContentHeader />
      <SuccessMessage />
    </Layout>
  )
}
